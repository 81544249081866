.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: none 1fr none;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "pageTitle"
    "pageBody"
    "addPermitBtn";
  margin: auto;
  width: 90%;
}
.pageTitle {
  grid-area: pageTitle;
  justify-self: center;
  font-size: large;
  font-weight: bold;
  padding: 15px;
}
.pageBody { grid-area: pageBody; }
.addPermitBtn {
  grid-area: addPermitBtn;
  justify-self: center;
  padding-top: 40px;
}