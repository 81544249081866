.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: .1fr .1fr none .1fr none;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "newPermit"
    "permittedVehLbl"
    "permittedVeh"
    "unpermittedVehLbl"
    "unpermittedVeh";
  margin: auto;
  width: 90%;
  padding-top: 30px;
  padding-bottom: 70px;
}
.newPermit { 
  grid-area: newPermit;
  text-align: center;
  padding-bottom: 20px;
}
.permittedVehLbl {
  grid-area: permittedVehLbl;
  font-size: larger;
  font-weight: bolder;
}
.permittedVeh {
  grid-area: permittedVeh;
  padding-top: 20px;
}
.unpermittedVehLbl {
  grid-area: unpermittedVehLbl;
  font-size: larger;
  font-weight: bolder;
  padding-top: 20px;
}
.unpermittedVeh { 
  grid-area: unpermittedVeh;
  padding-top: 20px;
}



.activePanelStyle {
  background-color: #0EC47E;
  color: #FFF;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.inActivePanelStyle {
  background-color: #999;
  color: #FFF;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.accordionStyle {
  font-weight: bolder;
}