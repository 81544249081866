.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: none none;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "title"
    "notifcation";
  margin: auto;
  width: 90%;
  padding-top: 30px;
  padding-bottom: 70px;
}
.title {
  grid-area: title;
  font-size: larger;
  font-weight: bolder;
}
.notification {
  grid-area: notifcation;
  padding-top: 20px;
}