.container { 
  display: flex;
  background-color: '#fff';
  padding: 10px;
}

.logo {
  align-self: center;
  justify-content: left;
  width: 85%;
  margin-left: 10px;
}

.language {
  align-self: center;
  justify-content: center;
}

.help {
  width: 15%;
  align-self: center;
}

.helpBtn {
  cursor: pointer;
}