.formContainer {
  display: flex;
  text-align: center;
}

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: .5fr .5fr .5fr .5fr .5fr 1fr ;
  gap: 0px 0px;
  grid-template-areas:
    "logo"
    "title"
    "searchText"
    "search"
    "btns"
    "propertyList";
  margin: auto;
  width: 80%;
}
.logo { 
  grid-area: logo;
  padding: 10px;
}
.title { 
  grid-area: title;
  text-transform: uppercase;
  font-weight: bolder;
  font-size: larger;
}
.searchText { grid-area: searchText; }
.search { grid-area: search; }
.btns { grid-area: btns }
.propertyList {
  grid-area: propertyList;
  padding-top: 20px;
  margin: auto;
  width: 50%;
}

.haveAccount { 
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}