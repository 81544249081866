.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: .25fr .5fr .5fr .5fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "pageTitle"
    "password"
    "passwordRequirements"
    "btns";
  margin: auto;
  width: 80%;
  text-align: center;
  padding-bottom: 20px;
}
.pageTitle {
  grid-area: pageTitle;
  font-size: larger;
  font-weight: bolder;
  padding: 20px;
}
.password { grid-area: password; }
.passwordRequirements { grid-area: passwordRequirements; }
.btns { 
  grid-area: btns;
  margin-top: 15px;
}