.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: none none none none none none none;
  gap: 0px 5px;
  grid-auto-flow: row;
  grid-template-areas:
    "pageTitle pageTitle"
    "plate plate"
    "state state"
    "lastSixVin lastSixVin"
    "make model"
    "year colors"
    "notice notice"
    "buttons buttons"
    "or or";
  text-align: center;
  margin: auto;
  width: 90%;
}
.pageTitle { 
  grid-area: pageTitle;
  font-size: large;
  font-weight: bold;
  padding: 10px;
}
.plate { grid-area: plate; }
.state { grid-area: state; }
.lastSixVin { grid-area: lastSixVin; }
.make { grid-area: make; }
.model { grid-area: model; }
.year { grid-area: year; }
.colors { grid-area: colors; }
.notice {
  grid-area: notice;
  border-radius: 10px;
  background-color: blanchedalmond;
  font-size: small;
  width: 90%;
  justify-self: center;
  text-align: left;
  padding: 10px;
  margin-bottom: 15px;
}
.buttons { 
  grid-area: buttons;
  margin-bottom: 10px;
}
.or { 
  grid-area: or;
  font-size: large;
  font-weight: bold;
  margin-bottom: 10px;
}