.container {
  display: grid; 
  grid-template-columns: 1fr; 
  grid-template-rows: .25fr .25fr 1fr .25fr; 
  gap: 0px 0px; 
  grid-template-areas: 
    "header"
    "pageTitle"
    "details"
    "btns"; 
  margin: auto;
  width: 80%;
  text-align: center;
}
.header { grid-area: header; }
.pageTitle {
  grid-area: pageTitle;
  font-size: larger;
  font-weight: bolder;
  padding-top: 20px;
  padding-bottom: 20px;
}
.details { grid-area: details; }
.btns {
  grid-area: btns;
  padding-top: 20px;
}


.detailsContainer {  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  gap: 5px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "acctTypeLbl acctType"
    "nameLbl name"
    "emailLbl email"
    "mobileLbl mobile"
    "unitLbl unit";
  margin: auto;
}
.acctTypeLbl { 
  grid-area: acctTypeLbl;
  text-align: right;
  font-weight: bold;
}
.acctType {
  grid-area: acctType;
  text-align: left;
  padding-left: 5px;
}
.nameLbl {
  grid-area: nameLbl;
  text-align: right;
  font-weight: bold;
}
.name {
  grid-area: name;
  text-align: left;
  padding-left: 5px;
}
.emailLbl {
  grid-area: emailLbl;
  text-align: right;
  font-weight: bold;
}
.email {
  grid-area: email;
  text-align: left;
  padding-left: 5px;
}
.mobileLbl {
  grid-area: mobileLbl;
  text-align: right;
  font-weight: bold;
}
.mobile {
  grid-area: mobile;
  text-align: left;
  padding-left: 5px;
}
.unitLbl {
  grid-area: unitLbl;
  text-align: right;
  font-weight: bold;
}
.unit {
  grid-area: unit;
  text-align: left;
  padding-left: 5px;
}

.haveAccount { 
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}