.formContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: none none none none none none none;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "pageTitle"
    "nameInput"
    "emailInput"
    "phoneInput"
    "propertyInput"
    "message"
    "btn";
  margin-top: 20px;
}
.pageTitle {
  grid-area: pageTitle;
  font-size: large;
  font-weight: bold;
  padding-bottom: 15px;
}
.nameInput { grid-area: nameInput; }
.emailInput { grid-area: emailInput; }
.phoneInput { grid-area: phoneInput; }
.propertyInput { grid-area: propertyInput; }
.message { grid-area: message; }
.btn {
  grid-area: btn;
  margin-top: 10px;
  justify-self: center;
}