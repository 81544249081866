.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: none none;
  gap: 0px 0px;
  grid-template-areas:
    "field"
    "btn";
}
.field { 
  grid-area: field;
  padding-top: 10px;
}
.btn {
  grid-area: btn;
  justify-self: center;
  padding-top: 10px;
}