.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: none none none;
  gap: 0px 0px;
  grid-template-areas:
    "title"
    "explanation"
    "form";
  margin: auto;
  width: 70%;
  padding-top: 30px;
}
.title { 
  grid-area: title;
  text-transform: uppercase;
  font-weight: bolder;
  font-size: larger;
  justify-self: center;
}
.explanation {
  grid-area: explanation;
  justify-self: center;
}
.form {
  grid-area: form;
  justify-self: center;
}