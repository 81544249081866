.container { 
  display: flex;
  justify-content: right;
}

.language {
  align-self: center;
}

.help {
  width: 115px;
}

.helpBtn {
  color: #0EC47E;
  border-color: #0EC47E;
}