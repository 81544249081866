.formContainer {
  display: flex;
  text-align: center;
}

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: .5fr .5fr .5fr .5fr .5fr;
  gap: 0px 0px;
  grid-template-areas:
    "logo"
    "title"
    "explanation"
    "field"
    "btn";
  margin: auto;
  width: 70%;
}
.logo { 
  grid-area: logo;
  padding-top: 10px;
}
.title { 
  grid-area: title;
  text-transform: uppercase;
  font-weight: bolder;
  font-size: larger;
  padding-top: 20px;
}
.explanation { grid-area: explanation; }
.field { 
  grid-area: field;
  padding-top: 20px;
}
.btn { 
  grid-area: btn;
  margin-top: 15px;
}