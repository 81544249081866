.container {  
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: .25fr 1fr 1fr;
    grid-auto-columns: 1fr;
    gap: 0px 5px;
    grid-auto-flow: row;
    grid-template-areas:
      "pageTitle"
      "unitNumber"
      "buttons";
    text-align: center;
    margin: auto;
    width: 90%;
  }
.pageTitle {
    grid-area: pageTitle;
    font-size: large;
    font-weight: bold;
    padding: 10px;
}
.unitNumber {
    grid-area: unitNumber;
    margin-top: 25px;
}
.buttons {
    grid-area: buttons;
}