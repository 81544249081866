.closeBtn {
  grid-area: closeBtn;
  margin: 10px;
  text-align: right;
}

.pageTitle {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: larger;
  font-weight: bolder;
  text-align: center;
}

.permit_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: .5fr 1fr .1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "permitTitle permitTitle"
    "permitInfo permitInfo"
    "lblTotal totalAmt";
  margin: auto;
  width: 80%;
  border: 1px solid #999;
  border-radius: 10px;
  padding: 15px;
}
.permitTitle {
  grid-area: permitTitle;
  display: inline-block;
  justify-self: center;
  font-size: larger;
  font-weight: bolder;
  color: #0EC47E;
}
.permitInfo {
  grid-area: permitInfo;
  margin-bottom: 20px;
}
.lblTotal {
  grid-area: lblTotal;
  font-size: larger;
  font-weight: bolder;
}
.totalAmt {
  grid-area: totalAmt;
  justify-self: right;
  font-size: larger;
  font-weight: bolder;
}