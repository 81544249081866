.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: none none;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "closeBtn"   
    "form";
  text-align: center;
  margin: auto;
  width: 90%;
}
.closeBtn {
  grid-area: closeBtn;
  margin: 10px;
  justify-self: right;
}
.form { grid-area: form; }