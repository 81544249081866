.navbar {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 400px;
  border-top: 2px solid #E6E6E6;
  padding: 5px;
  background-color: #FFF;
}

.navbarBlue {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 400px;
  border-top: 2px solid #0072CF;
  padding: 5px;
  background-color: #0072CF;
}

.navbarItem {
  width: 25%;
  align-self: center;
  text-align: center;
}

.subNav {
  display: flex;
  cursor: pointer;
  width: 100%;
  padding: 10px;
}

.subNavBtn {
  width: 40px;
  align-self: center;
  border: 5px;
}

.subNavLabel {
  text-indent: 5px;
  align-self: center;
  text-transform: uppercase;
  color: #0EC47E;
}

#topBox:clicked {
  rotate: 180deg;
}