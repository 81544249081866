.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: none none none none none;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "closeBtn"
    "chooseVehicle"
    'orAddVehicle'
    "form"
    'editOther';
  text-align: center;
  margin: auto;
  width: 90%;
}
.closeBtn {
  grid-area: closeBtn;
  margin: 10px;
  justify-self: right;
}
.chooseVehicle { grid-area: chooseVehicle; }
.orAddVehicle { 
  grid-area: orAddVehicle;
  font-size: larger;
  font-weight: bolder;
  padding-bottom: 10px;
}
.form { grid-area: form; }
.editOther { 
  grid-area: editOther; 
  padding-bottom: 80px;
}