.container { 
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: none none none;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "pageTitle"
    "acctInfo"
    "btns";
  margin: auto;
  width: 70%;
}
.pageTitle {
  grid-area: pageTitle;
  justify-self: center;
  font-size: large;
  font-weight: bold;
  margin-top: 20px;
}
.acctInfo {
  grid-area: acctInfo;
  margin: 10px;
}
.btns {
  grid-area: btns;
  margin-top: 10px;
}