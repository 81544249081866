.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: none none none none none none none none none;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "closeBtn"  
    "communityLbl"
    "communityName"
    "choosePermitType"
    "availablePermitTypeList"
    "chooseGuestPermit"
    "guestPermitBtn"
    "covertToResidentLbl"
    "covertToResidentBtn";
  margin: auto;
  width: 85%;
}
.closeBtn {
  grid-area: closeBtn;
  padding-bottom: 20px;
  margin: 20px;
  justify-self: right;
}
.communityLbl {
  grid-area: communityLbl;
  font-size: larger;
  font-weight: bolder;
}
.communityName {
  grid-area: communityName;
  font-size: large;
  font-weight: bold;
  color: #0EC47E;
  display: flex;
  text-indent: 10px;
  margin-top: 10px;
  width: 100%;
}
.choosePermitType {
  grid-area: choosePermitType;
  font-size: larger;
  font-weight: bolder;
  display: flex;
  padding-top: 20px;
}
.availablePermitTypeList {
  grid-area: availablePermitTypeList;
  justify-self: center;
  padding-top: 20px;
  margin: auto;
  width: 80%;
}
.chooseGuestPermit {
  grid-area: chooseGuestPermit;
  font-size: larger;
  font-weight: bolder;
  display: flex;
  padding-top: 20px;
}
.guestPermitBtn {
  grid-area: guestPermitBtn;
  justify-self: center;
  padding-top: 20px;
  margin: auto;
  width: 80%;
}
.covertToResidentLbl {
  grid-area: covertToResidentLbl;
  justify-self: center;
  padding-top: 20px;
  margin: auto;
  font-size: larger;
  display: flex;
  position: absolute;
  bottom: 70px;
}
.covertToResidentBtn {
  grid-area: covertToResidentBtn;
  justify-self: center;
  padding-top: 10px;
  margin: auto;
  position: absolute;
  bottom: 15px;

}