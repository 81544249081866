.modal {
  /* some styles to position the modal at the center of the page */
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* background-color: rgba(0,0,0,0.5); */

  /* needed styles for the overlay */
  z-index: 1000; /* keep on top of other elements on the page */
  /* box-shadow: 0 0 0 9999px rgba(0,0,0,0.5); */
}

.spinner {
  margin: 0px auto;
  width: 60px;
  height: 50px;
  font-size: 10px;
}

.spinner > div {
  background-color: #0EC47E;
  height: 100%;
  width: 6px;
  display: inline-block;
  margin: 1px;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

.overlay {
  margin: 0px auto;
  width: 60px;
  height: 50px;
  text-align: center;
  font-size: 10px;
}

.overlay > div {
  background-color: white;
  height: 100%;
  width: 6px;
  display: inline-block;
  margin: 1px;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}