.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: none none none none none none none;
  gap: 0px 3px;
  grid-auto-flow: row;
  grid-template-areas:
    "header header"
    "pageTitle pageTitle"
    "fName lName"
    "mobile mobile"
    "mobileDisclaimer mobileDisclaimer"
    "unit unit"
    "btns btns";
  margin: auto;
  width: 80%;
  text-align: center;
}
.header {
  grid-area: header;
  padding-top: 10px;
  padding-bottom: 40px;
}
.pageTitle {
  grid-area: pageTitle;
  font-size: larger;
  font-weight: bolder;
  padding-bottom: 20px;
}
.fName { 
  grid-area: fName;
  text-align: right;
}
.lName {
  grid-area: lName; 
  text-align: left;
}
.mobile { grid-area: mobile; }
.mobileDisclaimer { 
  grid-area: mobileDisclaimer;
  border-radius: 10px;
  background-color: blanchedalmond;
  font-size: small;
  width: 90%;
  justify-self: center;
  text-align: left;
  padding: 10px;
  margin-bottom: 15px;
}
.unit { grid-area: unit; }
.btns { 
  grid-area: btns;
  padding-top: 15px;
}

.haveAccount { 
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}