.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: none none none;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "header"
    "formBody"
    "faq";
  margin: 10px;
}
.header { grid-area: header; }
.formBody {
  grid-area: formBody;
  justify-self: center;
}
.faq {
  grid-area: faq;
  width: 85%;
  margin: auto;
}