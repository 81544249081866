.PymtTypeContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: .1fr .1fr .1fr;
  gap: 0px 5px;
  grid-auto-flow: row;
  grid-template-areas:
    "CCtype bankAccount"
    "pymtType pymtType"
    "btns btns";
  margin: auto;
  text-align: center;
  margin-top: 20px;
}
.CCtype {
  grid-area: CCtype;
  justify-self: right;
}
.bankAccount {
  grid-area: bankAccount;
  justify-self: left;
}
.pymtType {
  grid-area: pymtType;
  padding-top: 15px;
}
.btns {
  grid-area: btns;
  margin-top: 15px;
}


.CCcontainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: .1fr .1fr;
  gap: 0px 10px;
  grid-auto-flow: row;
  grid-template-areas:
    "ccNum ccNum"
    "expiration cvc";
  margin: auto;
}
.ccNum { grid-area: ccNum; }
.expiration {
  grid-area: expiration;
  justify-self: right;
}
.cvc { 
  grid-area: cvc;
  justify-self: left;
}


.ACHcontainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "routingNumb"
    "acctNum";
  margin: auto;
  text-align: center;
}
.routingNumb {
  grid-area: routingNumb;
  margin-top: 10px;
}
.acctNum { grid-area: acctNum; }