body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.actionButton {
  padding: 10px;
  background-color: #F39C12;
  border-color: #F39C12;
  color: #000;
  border-radius: 5px;
  border-style: solid;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: none;
  font-weight: bolder;
  min-width: 150px;
}