.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: .5fr .5fr .25fr .25fr .5fr;
  gap: 10px 0px;
  grid-template-areas:
    "header"
    "property"
    "resident"
    "guest"
    "goback";
  margin: auto;
  width: 90%;
  text-align: center;
}
.header { 
  grid-area: header;
  text-align: right;
  margin-bottom: 50px;
  padding-top: 5px;
}
.property { 
  grid-area: property;
  font-size: large;
}
.resident { grid-area: resident; }
.guest { grid-area: guest; }
.goback { grid-area: goback; }

.haveAccount { 
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}