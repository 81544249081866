.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: .5fr .25fr .5fr .5fr .5fr .5fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "header"
    "pageTitle"
    "message"
    "password"
    "passwordRequirements"
    "btns";
  margin: auto;
  width: 80%;
  text-align: center;
  padding-bottom: 20px;
}
.header { grid-area: header; }
.pageTitle {
  grid-area: pageTitle;
  font-size: larger;
  font-weight: bolder;
  padding-bottom: 10px;
}
.message { grid-area: message; }
.password { grid-area: password; }
.passwordRequirements { grid-area: passwordRequirements; }
.btns { grid-area: btns; }