.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: none none none none none none none none;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "firstName"
    "lastName"
    "mobile"
    "email"
    "address1"
    "city"
    "stateZip"
    "btns";
  justify-items: center;
}
.firstName { grid-area: firstName; }
.lastName { grid-area: lastName; }
.mobile { grid-area: mobile; }
.email {
  grid-area: email;
  display: flex;
  width: 80%;
  padding-bottom: 15px;
}
.address1 { grid-area: address1; }
.city { grid-area: city; }
.stateZip { 
  grid-area: stateZip;
  display: flex;
}
.btns {
  grid-area: btns;
  justify-self: center;
}