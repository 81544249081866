.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: none none none none;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "pageTitle pageTitle"
    "sendEmailMsg sendEmailMsg"
    "emailLbl email"
    "sendBtn sendBtn";
  margin: auto;
  width: 90%;
}
.pageTitle {
  grid-area: pageTitle;
  font-size: large;
  font-weight: bold;
  justify-self: center;
  padding-top: 20px;

}
.sendEmailMsg {
  grid-area: sendEmailMsg;
  justify-self: center;
  padding: 20px;
}
.emailLbl {
  grid-area: emailLbl;
  font-weight: bold;
  justify-self: right;
}
.email { 
  grid-area: email;
  text-indent: 5px;
}
.sendBtn {
  grid-area: sendBtn;
  justify-self: center;
  padding-top: 20px;
}